export enum ScreenNamesEnum {
  dashboard = 'dashboard',
  simulation = 'simulation',
  individualApproval = 'individualApproval',
  approvalBatch = 'approvalBatch',
  approval = 'approval',
  financial = 'financial',
  financialManagement = 'management',
  financialAntecipation = 'antecipation',
  faq = 'faq',
  woReport = 'woReport',
  students = 'students',
  studentsWallet = 'studentsWallet',
  searchStudents = 'searchStudents',
  transferExtractManagement = 'transferExtractManagement',
  antecipationExtract = 'antecipationExtract',
  operationalsDates = 'operationalsDates',
  fiscalDocuments = 'fiscalDocuments',
  avaliationHistory = 'avaliationHistory',
  transferCancellation = 'transferCancellation',
  secondTransfer = 'secondTransfer',
  loginPortal = 'loginPortal',
  empty = 'empty',
  myAccount = 'myAccount',
  transferEstimate = 'transferEstimate',
  userManagement = 'userManagement',
  createUser = 'createUser',
  contractEstimate = 'contractEstimate',
  helpdeskSearch = 'helpdeskSearch',
  detailsStudent = 'detailsStudent',
  calendar = 'calendar',
  documents = 'documents',
  insights = 'insights',
  easyInstallment = 'easyInstallment',
  easyInstallmentExtract = 'easyInstallmentExtract',
  easyInstallmentEstimate = 'easyInstallmentEstimate',
  grantedManagement = 'grantedManagement',
  grantedManagementExtract = 'grantedManagementExtract',
  grantedManagementEstimate = 'grantedManagementEstimate',
  invoiceConciliation = 'invoiceConciliation'
}

export type sidebarMenuItemType = {
  key: string;
  sub?: sidebarMenuItemType[];
};

export type sidebarMenuType = {
  menuList: sidebarMenuItemType[];
  menuItemSelected: ScreenNamesEnum;
};
