export type routeNamesType = {
  empty: string;
  financial: string;
  dashboard: string;
  approvalBatch: string;
  individualApproval: string;
  faq: string;
  loginPortal: string;
  simulator: string;
  antecipation: string;
  management: string;
  transferExtractManagement: string;
  transferEstimate: string;
  woReport: string;
  students: string;
  studentsWallet: string;
  searchStudents: string;
  detailsStudent: string;
  fiscalDocuments: string;
  avaliationHistory: string;
  antecipationExtract: string;
  operationalsDates: string;
  transferCancellation: string;
  secondTransfer: string;
  recoverPassword: string;
  myAccount: string;
  changePassword: string;
  forgotPassword: string;
  userManagement: string;
  createUser: string;
  contractEstimate: string;
  helpdeskSearch: string;
  insights: string;
  easyInstallment: string;
  easyInstallmentExtract: string;
  easyInstallmentEstimate: string;
  grantedManagement: string;
  grantedManagementExtract: string;
  grantedManagementEstimate: string;
  invoiceConciliation: string;
};

export const routesNames: routeNamesType = {
  empty: '',
  financial: '/financeiro',
  dashboard: '/dashboard',
  approvalBatch: '/aprovacao-lote',
  individualApproval: '/lista-aprovacao',
  faq: '/central-de-ajuda',
  helpdeskSearch: '/central-de-ajuda/buscar',
  loginPortal: '/',
  simulator: '/simulador',
  antecipation: '/antecipacao',
  antecipationExtract: '/antecipacao/extrato',
  management: '/gestao',
  transferExtractManagement: '/gestao/extrato-repasse',
  transferEstimate: '/gestao/estimativa-repasse',
  woReport: '/gestao/relatorio-wo',
  students: '/alunos',
  studentsWallet: '/alunos/carteira',
  searchStudents: '/alunos/buscar-alunos',
  detailsStudent: '/alunos/detalhes-aluno',
  contractEstimate: '/alunos/estimativa-contratos',
  operationalsDates: '/datas-operacionais',
  fiscalDocuments: '/documentos-fiscais',
  avaliationHistory: '/alunos/historico-avaliacoes',
  transferCancellation: 'cancelamento',
  secondTransfer: '/cancelamento/segundo-repasse',
  myAccount: '/minha-conta',
  changePassword: '/minha-conta/trocar-senha',
  forgotPassword: '/minha-conta/esqueci-a-senha',
  recoverPassword: '/recuperar-senha',
  userManagement: '/gestao-usuarios',
  createUser: '/gestao-usuarios/cadastrar-usuario',
  insights: '/insights',
  easyInstallment: '/repasse-garantido',
  easyInstallmentExtract: '/repasse-garantido/extrato',
  easyInstallmentEstimate: '/repasse-garantido/estimativa',
  grantedManagement: '/gestao-garantido',
  grantedManagementExtract: '/gestao-garantido/extrato',
  grantedManagementEstimate: '/gestao-garantido/estimativa',
  invoiceConciliation: '/conciliacao-fiscal'
};
